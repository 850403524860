import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	LabourRequest,
	CreateLabourRequestData,
	CreateLabourRequestResponse,
	createLabourRequest,
	updateLabourRequest,
	UpdateLabourRequestResponse,
	UpdateLabourRequestData,
	getALabourRequest,
	getAllLabourRequests,
	GetALabourRequestResponse,
	ReviewLabourRequestData,
	ReviewLabourRequestResponse,
	reviewLabourRequest,
	PettyRequest,
	CreatePettyRequestData,
	CreatePettyRequestResponse,
	createPettyRequest,
	updatePettyRequest,
	UpdatePettyRequestResponse,
	UpdatePettyRequestData,
	getAPettyRequest,
	getAllPettyRequests,
	GetAPettyRequestResponse,
	ReviewPettyRequestData,
	ReviewPettyRequestResponse,
	reviewPettyRequest,
	MaterialRequest,
	CreateMaterialRequestData,
	CreateMaterialRequestResponse,
	createMaterialRequest,
	updateMaterialRequest,
	UpdateMaterialRequestResponse,
	UpdateMaterialRequestData,
	getAMaterialRequest,
	getAllMaterialRequests,
	GetAMaterialRequestResponse,
	ReviewMaterialRequestData,
	ReviewMaterialRequestResponse,
	reviewMaterialRequest,
	GetAllPettyRequestsResponse,
	GetAllLabourRequestsResponse,
	GetAllMaterialRequestsResponse,
	searchMaterialRequest,
	searchLabourRequest,
	searchPettyRequest,
} from "../../services/requests/requestsService";

export interface RequestState {
	companyId?: string | undefined;
	createMaterialRequestResponse?: CreateMaterialRequestResponse | null;
	updateMaterialRequestResponse?: UpdateMaterialRequestResponse | null;
	reviewMaterialRequestResponse?: ReviewMaterialRequestResponse | null;
	getAMaterialRequestResponse?: GetAMaterialRequestResponse | null;
	createLabourRequestResponse?: CreateLabourRequestResponse | null;
	updateLabourRequestResponse?: UpdateLabourRequestResponse | null;
	reviewLabourRequestResponse?: ReviewLabourRequestResponse | null;
	getALabourRequestResponse?: GetALabourRequestResponse | null;
	createPettyRequestResponse?: CreatePettyRequestResponse | null;
	updatePettyRequestResponse?: UpdatePettyRequestResponse | null;
	reviewPettyRequestResponse?: ReviewPettyRequestResponse | null;
	getAPettyRequestResponse?: GetAPettyRequestResponse | null;
	materialRequests: GetAllMaterialRequestsResponse | null;
	materialRequestData?: MaterialRequest | null;
	materialRequestId?: string;
	labourRequests: GetAllLabourRequestsResponse | null;
	labourRequestData?: LabourRequest | null;
	labourRequestId?: string;
	pettyRequests: GetAllPettyRequestsResponse | null;
	pettyRequestData?: PettyRequest | null;
	pettyRequestId?: string;
	meta?: any;
}

const initialState: RequestState = {
	companyId: "",
	createMaterialRequestResponse: null,
	updateMaterialRequestResponse: null,
	reviewMaterialRequestResponse: null,
	getAMaterialRequestResponse: null,
	createLabourRequestResponse: null,
	updateLabourRequestResponse: null,
	reviewLabourRequestResponse: null,
	getALabourRequestResponse: null,
	createPettyRequestResponse: null,
	updatePettyRequestResponse: null,
	reviewPettyRequestResponse: null,
	getAPettyRequestResponse: null,
	materialRequests: null,
	materialRequestData: null,
	materialRequestId: "",
	labourRequests: null,
	labourRequestData: null,
	labourRequestId: "",
	pettyRequests: null,
	pettyRequestData: null,
	pettyRequestId: "",
	meta: null,
};

export const createMaterialRequestAsync = createAsyncThunk<
	CreateMaterialRequestResponse,
	CreateMaterialRequestData
>(
	"requests/createMaterialRequest",
	async (
		createMaterialRequestData: CreateMaterialRequestData,
		{ dispatch, rejectWithValue },
	) => {
		try {
			const response = await createMaterialRequest(createMaterialRequestData);
			if (response.data && response.data.id) {
				const materialRequestId = response.data.id;
				localStorage.setItem("materialRequestId", materialRequestId);
				dispatch(setMaterialRequestId(materialRequestId));
			}
			return response;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({
				error: true,
				message: "An unexpected error occurred",
			});
		}
	},
);
export const updateMaterialRequestAsync = createAsyncThunk(
	"requests/updateMaterialRequest",
	async ({
		updateMaterialRequestData,
		id,
	}: {
		updateMaterialRequestData: UpdateMaterialRequestData;
		id: string;
	}) => {
		const response = await updateMaterialRequest(updateMaterialRequestData, id);
		console.log("API response:", response);
		return response;
	},
);
export const getAllMaterialRequestsAsync = createAsyncThunk(
	"requests/getAllMaterialRequests",
	async ({
		companyId,
		projectId,
		page,
	}: {
		companyId: string;
		projectId: string;
		page: number;
	}) => {
		try {
			console.log("Company ID:", companyId);
			const materialRequests = await getAllMaterialRequests(
				companyId,
				projectId,
				page,
			);
			return materialRequests;
		} catch (error) {
			console.error("Error fetching material requests:", error);
			throw error;
		}
	},
);
export const searchMaterialRequestAsync = createAsyncThunk(
	"settings/searchMaterialRequest",
	async (
		{
			company_id,
			searchTerm,
			projectId,
			page,
		}: {
			company_id: string;
			searchTerm: string;
			projectId: string;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchMaterialRequest(
				company_id,
				searchTerm,
				projectId,
				page,
			);
			return response;
		} catch (err) {
			return rejectWithValue("Search material failed.");
		}
	},
);
export const getAMaterialRequestAsync = createAsyncThunk(
	"requests/getAMaterialRequest",
	async ({
		companyId,
		id,
		projectId,
	}: {
		companyId: string;
		id: string;
		projectId: string;
	}) => {
		try {
			console.log("Company ID:", companyId);
			const materialRequest = await getAMaterialRequest(
				companyId,
				id,
				projectId,
			);
			return materialRequest;
		} catch (error) {
			console.error("Error fetching material request:", error);
			throw error;
		}
	},
);
export const reviewMaterialRequestAsync = createAsyncThunk<
	ReviewMaterialRequestResponse,
	ReviewMaterialRequestData
>(
	"requests/reviewMaterialRequest",
	async (
		reviewMaterialRequestData: ReviewMaterialRequestData,
		{ rejectWithValue },
	) => {
		try {
			const response = await reviewMaterialRequest(reviewMaterialRequestData);
			return response;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({
				error: true,
				message: "An unexpected error occurred",
			});
		}
	},
);
export const createLabourRequestAsync = createAsyncThunk<
	CreateLabourRequestResponse,
	CreateLabourRequestData
>(
	"requests/createLabourRequest",
	async (
		createLabourRequestData: CreateLabourRequestData,
		{ dispatch, rejectWithValue },
	) => {
		try {
			const response = await createLabourRequest(createLabourRequestData);
			if (response.data && response.data.id) {
				const labourRequestId = response.data.id;
				localStorage.setItem("labourRequestId", labourRequestId);
				dispatch(setLabourRequestId(labourRequestId));
			}
			return response;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({
				error: true,
				message: "An unexpected error occurred",
			});
		}
	},
);
export const updateLabourRequestAsync = createAsyncThunk(
	"requests/updateLabourRequest",
	async ({
		updateLabourRequestData,
		id,
	}: {
		updateLabourRequestData: UpdateLabourRequestData;
		id: string;
	}) => {
		const response = await updateLabourRequest(updateLabourRequestData, id);
		console.log("API response:", response);
		return response;
	},
);
export const getAllLabourRequestsAsync = createAsyncThunk(
	"requests/getAllLabourRequests",
	async ({
		companyId,
		projectId,
		page,
	}: {
		companyId: string;
		projectId: string;
		page: number;
	}) => {
		try {
			console.log("Company ID:", companyId);
			const labourRequests = await getAllLabourRequests(
				companyId,
				projectId,
				page,
			);
			return labourRequests;
		} catch (error) {
			console.error("Error fetching labour requests:", error);
			throw error;
		}
	},
);
export const searchLabourRequestAsync = createAsyncThunk(
	"settings/searchLabourRequest",
	async (
		{
			company_id,
			searchTerm,
			projectId,
			page,
		}: {
			company_id: string;
			searchTerm: string;
			projectId: string;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchLabourRequest(
				company_id,
				searchTerm,
				projectId,
				page,
			);
			return response;
		} catch (err) {
			return rejectWithValue("Search Labour failed.");
		}
	},
);
export const getALabourRequestAsync = createAsyncThunk(
	"requests/getALabourRequest",
	async ({
		companyId,
		id,
		projectId,
	}: {
		companyId: string;
		id: string;
		projectId: string;
	}) => {
		try {
			console.log("Company ID:", companyId);
			const labourRequest = await getALabourRequest(companyId, id, projectId);
			return labourRequest;
		} catch (error) {
			console.error("Error fetching labour request:", error);
			throw error;
		}
	},
);
export const reviewLabourRequestAsync = createAsyncThunk<
	ReviewLabourRequestResponse,
	ReviewLabourRequestData
>(
	"requests/reviewLabourRequest",
	async (
		reviewLabourRequestData: ReviewLabourRequestData,
		{ rejectWithValue },
	) => {
		try {
			const response = await reviewLabourRequest(reviewLabourRequestData);
			return response;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({
				error: true,
				message: "An unexpected error occurred",
			});
		}
	},
);
export const createPettyRequestAsync = createAsyncThunk<
	CreatePettyRequestResponse,
	CreatePettyRequestData
>(
	"requests/createPettyRequest",
	async (
		createPettyRequestData: CreatePettyRequestData,
		{ dispatch, rejectWithValue },
	) => {
		try {
			const response = await createPettyRequest(createPettyRequestData);
			if (response.data && response.data.id) {
				const pettyRequestId = response.data.id;
				localStorage.setItem("pettyRequestId", pettyRequestId);
				dispatch(setPettyRequestId(pettyRequestId));
			}
			return response;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({
				error: true,
				message: "An unexpected error occurred",
			});
		}
	},
);
export const updatePettyRequestAsync = createAsyncThunk(
	"requests/updatePettyRequest",
	async ({
		updatePettyRequestData,
		id,
	}: {
		updatePettyRequestData: UpdatePettyRequestData;
		id: string;
	}) => {
		const response = await updatePettyRequest(updatePettyRequestData, id);
		return response;
	},
);
export const getAllPettyRequestsAsync = createAsyncThunk(
	"requests/getAllPettyRequests",
	async ({
		companyId,
		projectId,
		page,
	}: {
		companyId: string;
		projectId: string;
		page: number;
	}) => {
		try {
			console.log("Company ID:", companyId);
			const pettyRequests = await getAllPettyRequests(
				companyId,
				projectId,
				page,
			);
			return pettyRequests;
		} catch (error) {
			console.error("Error fetching petty cash requests:", error);
			throw error;
		}
	},
);
export const searchPettyRequestAsync = createAsyncThunk(
	"settings/searchPettyRequest",
	async (
		{
			company_id,
			searchTerm,
			projectId,
			page,
		}: {
			company_id: string;
			searchTerm: string;
			projectId: string;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchPettyRequest(
				company_id,
				searchTerm,
				projectId,
				page,
			);
			return response;
		} catch (err) {
			return rejectWithValue("Search Petty cash failed.");
		}
	},
);
export const getAPettyRequestAsync = createAsyncThunk(
	"requests/getAPettyRequest",
	async ({
		companyId,
		id,
		projectId,
	}: {
		companyId: string;
		id: string;
		projectId: string;
	}) => {
		try {
			console.log("Company ID:", companyId);
			const pettyRequest = await getAPettyRequest(companyId, id, projectId);
			return pettyRequest;
		} catch (error) {
			console.error("Error fetching petty cash request:", error);
			throw error;
		}
	},
);
export const reviewPettyRequestAsync = createAsyncThunk<
	ReviewPettyRequestResponse,
	ReviewPettyRequestData
>(
	"requests/reviewPettyRequest",
	async (
		reviewPettyRequestData: ReviewPettyRequestData,
		{ rejectWithValue },
	) => {
		try {
			const response = await reviewPettyRequest(reviewPettyRequestData);
			return response;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({
				error: true,
				message: "An unexpected error occurred",
			});
		}
	},
);
const requestsSlice = createSlice({
	name: "requests",
	initialState,
	reducers: {
		setMaterialRequestId: (state, action) => {
			state.materialRequestId = action.payload;
		},
		setLabourRequestId: (state, action) => {
			state.labourRequestId = action.payload;
		},
		setPettyRequestId: (state, action) => {
			state.pettyRequestId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(createMaterialRequestAsync.fulfilled, (state, action) => {
			state.createMaterialRequestResponse = action.payload;
		});
        builder.addCase(createMaterialRequestAsync.rejected, (state) => {
            state.createMaterialRequestResponse = null;
        });
        builder.addCase(createMaterialRequestAsync.pending, (state) => {
            state.createMaterialRequestResponse = null;
        });
    
		builder.addCase(updateMaterialRequestAsync.fulfilled, (state, action) => {
			state.updateMaterialRequestResponse = action.payload;
		});
        builder.addCase(updateMaterialRequestAsync.rejected, (state) => {
            state.updateMaterialRequestResponse = null;
        });
        builder.addCase(updateMaterialRequestAsync.pending, (state) => {
            state.updateMaterialRequestResponse = null;
        });
    
		builder.addCase(getAllMaterialRequestsAsync.fulfilled, (state, action) => {
			state.materialRequests = action.payload;
			state.meta = action.payload.meta;
		});
		builder.addCase(searchMaterialRequestAsync.fulfilled, (state, action) => {
			state.materialRequests = action.payload;
			state.meta = action.payload.data;
		});
        builder.addCase(getAllMaterialRequestsAsync.rejected, (state) => {
            state.materialRequests = null;
            state.meta = null;
        });
        builder.addCase(getAllMaterialRequestsAsync.pending, (state) => {
            state.materialRequests = null;
            state.meta = null;
        });
    
		builder.addCase(getAMaterialRequestAsync.fulfilled, (state, action) => {
			state.materialRequestData = action.payload;
		});
        builder.addCase(getAMaterialRequestAsync.rejected, (state) => {
            state.materialRequestData = null;
        });
        builder.addCase(getAMaterialRequestAsync.pending, (state) => {
            state.materialRequestData = null;
        });
    
		builder.addCase(reviewMaterialRequestAsync.fulfilled, (state, action) => {
			state.reviewMaterialRequestResponse = action.payload;
		});
        builder.addCase(reviewMaterialRequestAsync.rejected, (state) => {
            state.reviewMaterialRequestResponse = null;
        });
        builder.addCase(reviewMaterialRequestAsync.pending, (state) => {
            state.reviewMaterialRequestResponse = null;
        });
    
		builder.addCase(createLabourRequestAsync.fulfilled, (state, action) => {
			state.createLabourRequestResponse = action.payload;
		});
        builder.addCase(createLabourRequestAsync.rejected, (state) => {
            state.createLabourRequestResponse = null;
        });
        builder.addCase(createLabourRequestAsync.pending, (state) => {
            state.createLabourRequestResponse = null;
        });
    
		builder.addCase(updateLabourRequestAsync.fulfilled, (state, action) => {
			state.updateLabourRequestResponse = action.payload;
		});
        builder.addCase(updateLabourRequestAsync.rejected, (state) => {
            state.updateLabourRequestResponse = null;
        });
        builder.addCase(updateLabourRequestAsync.pending, (state) => {
            state.updateLabourRequestResponse = null;
        });
    
		builder.addCase(getAllLabourRequestsAsync.fulfilled, (state, action) => {
			state.labourRequests = action.payload;
			state.meta = action.payload.meta;
		});
		builder.addCase(searchLabourRequestAsync.fulfilled, (state, action) => {
			state.labourRequests = action.payload;
			state.meta = action.payload.data;
		});
        builder.addCase(getAllLabourRequestsAsync.rejected, (state) => {
            state.labourRequests = null;
            state.meta = null;
        });
        builder.addCase(getAllLabourRequestsAsync.pending, (state) => {
            state.labourRequests = null;
            state.meta = null;
        });
    
		builder.addCase(getALabourRequestAsync.fulfilled, (state, action) => {
			state.labourRequestData = action.payload;
		});
        builder.addCase(getALabourRequestAsync.rejected, (state) => {
            state.labourRequestData = null;
        });
        builder.addCase(getALabourRequestAsync.pending, (state) => {
            state.labourRequestData = null;
        });
    
		builder.addCase(reviewLabourRequestAsync.fulfilled, (state, action) => {
			state.reviewLabourRequestResponse = action.payload;
		});
        builder.addCase(reviewLabourRequestAsync.rejected, (state) => {
            state.reviewLabourRequestResponse = null;
        });
        builder.addCase(reviewLabourRequestAsync.pending, (state) => {
            state.reviewLabourRequestResponse = null;
        });
    
		builder.addCase(createPettyRequestAsync.fulfilled, (state, action) => {
			state.createPettyRequestResponse = action.payload;
		});
        builder.addCase(createPettyRequestAsync.rejected, (state) => {
            state.createPettyRequestResponse = null;
        });
        builder.addCase(createPettyRequestAsync.pending, (state) => {
            state.createPettyRequestResponse = null;
        });
    
		builder.addCase(updatePettyRequestAsync.fulfilled, (state, action) => {
			state.updatePettyRequestResponse = action.payload;
		});
        builder.addCase(updatePettyRequestAsync.rejected, (state) => {
            state.updatePettyRequestResponse = null;
        });
        builder.addCase(updatePettyRequestAsync.pending, (state) => {
            state.updatePettyRequestResponse = null;
        });
    
		builder.addCase(getAllPettyRequestsAsync.fulfilled, (state, action) => {
			state.pettyRequests = action.payload;
			state.meta = action.payload.meta;
		});
		builder.addCase(searchPettyRequestAsync.fulfilled, (state, action) => {
			state.pettyRequests = action.payload;
			state.meta = action.payload.data;
		});
        builder.addCase(getAllPettyRequestsAsync.rejected, (state) => {
            state.pettyRequests = null;
            state.meta = null;
        });
        builder.addCase(getAllPettyRequestsAsync.pending, (state) => {
            state.pettyRequests = null;
            state.meta = null;
        });
    
		builder.addCase(getAPettyRequestAsync.fulfilled, (state, action) => {
			state.pettyRequestData = action.payload;
		});
        builder.addCase(getAPettyRequestAsync.rejected, (state) => {
            state.pettyRequestData = null;
        });
        builder.addCase(getAPettyRequestAsync.pending, (state) => {
            state.pettyRequestData = null;
        });
    
		builder.addCase(reviewPettyRequestAsync.fulfilled, (state, action) => {
			state.reviewPettyRequestResponse = action.payload;
		});
        builder.addCase(reviewPettyRequestAsync.rejected, (state) => {
            state.reviewPettyRequestResponse = null;
        });
        builder.addCase(reviewPettyRequestAsync.pending, (state) => {
            state.reviewPettyRequestResponse = null;
        });
	},
});
export const { setLabourRequestId, setPettyRequestId, setMaterialRequestId } =
	requestsSlice.actions;
export default requestsSlice.reducer;
