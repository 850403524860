import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	createUser,
	CreateUserData,
	CreateUserResponse,
	getAllUsers,
	getAUser,
	GetAUserResponse,
	updateUserStatus,
	UpdateUserStatusData,
	UpdateUserStatusResponse,
	updateUser,
	UpdateUserData,
	UpdateUserResponse,
	User,
	GetAllUsersResponse,
	searchUser,
	getAllDropdownUsers,
} from "../../services/user/userService";

export interface UserState {
	createUserResponse?: CreateUserResponse | null;
	updateUserResponse?: UpdateUserResponse | null;
	getAUserResponse?: GetAUserResponse | null;
	updateUserStatusResponse?: UpdateUserStatusResponse | null;
	userId?: string;
	users?: GetAllUsersResponse | null;
	userData?: User | null;
	permissions: any;
	meta?: any;
}

const initialState: UserState = {
	createUserResponse: null,
	updateUserResponse: null,
	getAUserResponse: null,
	updateUserStatusResponse: null,
	userId: "",
	users: null,
	userData: null,
	permissions: null,
	meta: null,
};

export const createUserAsync = createAsyncThunk(
	"user/createUser",
	async (createUserData: CreateUserData, { dispatch, rejectWithValue }) => {
		try {
			const response = await createUser(createUserData);
			if (response.data && response.data.id) {
				const userId = response.data.id;
				localStorage.setItem("userId", userId);
				dispatch(setUserId(userId));
			}
			return response;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({
				error: true,
				message: "An unexpected error occurred",
			});
		}
	},
);
export const updateUserAsync = createAsyncThunk(
	"user/updateuser",
	async (
		{ updateUserData, id }: { updateUserData: UpdateUserData; id: string },
		{ rejectWithValue },
	) => {
		try {
			const response = await updateUser(updateUserData, id);
			return response;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({
				error: true,
				message: "An unexpected error occurred",
			});
		}
	},
);
export const getAllUsersAsync = createAsyncThunk(
	"user/getAllUsers",
	async ({ companyId, page }: { companyId: string; page: number }) => {
		try {
			console.log("Company ID:", companyId);
			const users = await getAllUsers(companyId, page);
			return users;
		} catch (error) {
			console.error("Error fetching users:", error);
			throw error;
		}
	},
);
export const getAllDropdownUsersAsync = createAsyncThunk(
	"user/getAllUsers",
	async ({ companyId }: { companyId: string; }) => {
		try {
			console.log("Company ID:", companyId);
			const users = await getAllDropdownUsers(companyId);
			return users;
		} catch (error) {
			console.error("Error fetching users:", error);
			throw error;
		}
	},
);
export const searchUserAsync = createAsyncThunk(
	"settings/searchUser",
	async (
		{
			company_id,
			searchTerm,
			page,
		}: {
			company_id: string;
			searchTerm: string;
			page: number;
		},
		{ rejectWithValue },
	) => {
		try {
			const response = await searchUser(company_id, searchTerm, page);
			return response;
		} catch (err) {
			return rejectWithValue("Search User failed.");
		}
	},
);
export const getAUserAsync = createAsyncThunk(
	"user/getAUser",
	async ({ companyId, id }: { companyId: string; id: string }) => {
		try {
			console.log("Company ID:", companyId);
			const user = await getAUser(companyId, id);
			return user;
		} catch (error) {
			console.error("Error fetching user:", error);
			throw error;
		}
	},
);
export const updateUserStatusAsync = createAsyncThunk(
	"user/updateUserStatus",
	async (data: UpdateUserStatusData, { rejectWithValue }) => {
		try {
			const response = await updateUserStatus(data);
			return response;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({
				error: true,
				message: "An unexpected error occurred",
			});
		}
	},
);

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUserId: (state, action) => {
			state.userId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(createUserAsync.fulfilled, (state, action) => {
			state.createUserResponse = action.payload;
		});
		builder.addCase(updateUserAsync.fulfilled, (state, action) => {
			state.updateUserResponse = action.payload;
		});
		builder.addCase(getAllUsersAsync.fulfilled, (state, action) => {
			state.users = action.payload;
			state.meta = action.payload.meta;
		});
		builder.addCase(searchUserAsync.fulfilled, (state, action) => {
			state.users = action.payload;
			state.meta = action.payload.meta;
		});
		builder.addCase(getAUserAsync.fulfilled, (state, action) => {
			state.userData = action.payload;
			state.permissions = action.payload.permissions;
		});
		builder.addCase(updateUserStatusAsync.fulfilled, (state, action) => {
			state.updateUserStatusResponse = action.payload;
		});
	},
});

export const { setUserId } = userSlice.actions;
export default userSlice.reducer;
