import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Table } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getAMaterialRequestAsync, setMaterialRequestId
} from '../../../../slices/requests/requestsSlice';
import { RootState } from "../../../../store/store";
import { format, isValid } from 'date-fns';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
interface ViewMaterialRequestProps {
    closeModal: () => void;
    show: boolean;
    materialRequestId: string;
    projectId: string;
}

const ViewMaterialRequest: React.FC<ViewMaterialRequestProps> = ({ closeModal, show, materialRequestId, projectId }) => {
    const formatCurrency = (amount: number): string => `₦${amount.toLocaleString('en-US')}`;
    const formatDateTime = (dateTime: string | Date): string => {
        if (!dateTime) return "Invalid Date";
        const date = new Date(dateTime);
        return isValid(date) ? format(date, "dd/MM/yy h:mm a") : "Invalid Date";
    };

    const dispatch = useAppDispatch();
    const companyId = useAppSelector((state: RootState) => state.auth.user?.company_id || "");
    const materialRequestData = useAppSelector((state: any) => state.requests.materialRequestData);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (materialRequestId) {
            dispatch(setMaterialRequestId(materialRequestId));
            dispatch(getAMaterialRequestAsync({ companyId, id: materialRequestId, projectId })).then(() => setLoading(false));
        }
    }, [dispatch, companyId, materialRequestId, projectId]);

    if (loading) {
        return null;
    }

    const reviewLevel = materialRequestData?.material_requests?.review_level;
    const reviews = materialRequestData?.material_requests?.reviews;

    const dotStyles = (step: number) => {
        const status = materialRequestData?.material_requests?.status;
        const payment_status = materialRequestData?.material_requests?.payment_status; if (step === 1 && status === 'approved') return '#0f6cbd';
        if (step === 2 && status === 'reviewed') return '#0f6cbd';
        if (step === 3 && status === 'approved') return '#0f6cbd';
        if (step === 4) {
            if (payment_status === 'paid') return '#0f6cbd';
            if (payment_status === 'unpaid') return '#ccc';
        }
        return '#ccc';
    };
    console.log("paid at", materialRequestData?.material_requests?.paid_at)
    return (
        <Container>
            <Modal centered size="xl" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='px-3 '>
                    <Modal.Header closeButton className='text-start'>
                        <h4 className="confirm-header">View material request</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-2 pb-2'>
                            <Col md={8} className="col-separator">
                                <Table className="rectangle" borderless responsive>
                                    <thead>
                                        <tr>
                                            <td className='text-color-7'>S/N</td>
                                            <td className='text-color-7'>Material </td>
                                            <td className='text-color-7'>Quantity </td>
                                            <td className='text-color-7'>Cost </td>
                                            <td className='text-color-7'>Vendor name </td>
                                            <td className='text-color-7'>Bank </td>
                                            <td className='text-color-7'>Acct no </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {materialRequestData?.material_requests?.materials.map((material: any, index: any) => (
                                            <tr key={material.id}>
                                                <td className='text-color-7'>{index + 1}</td>
                                                <td className='text-color-7'>{material.material_name}</td>
                                                <td className='text-color-7'>{material.quantity}</td>
                                                <td className='text-color-7'>{formatCurrency(parseFloat(material.unit_cost))}</td>
                                                <td className='text-color-7'>{materialRequestData?.material_requests?.vendor_name}</td>
                                                <td className='text-color-7'>{materialRequestData?.material_requests?.bank_name}</td>
                                                <td className='text-color-7'>{materialRequestData?.material_requests?.account_number}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Timeline
                                    placeholder="Timeline"
                                    onPointerEnterCapture={() => { }}
                                    onPointerLeaveCapture={() => { }}
                                    sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{ bgcolor: dotStyles(1) }} />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Row>
                                                <small>Request initiated by {materialRequestData?.material_requests?.created_by} </small>
                                            </Row>
                                            <Row>
                                                <small>{formatDateTime(materialRequestData?.material_requests?.created_on)}</small>
                                            </Row>
                                        </TimelineContent>
                                    </TimelineItem>
                                    {reviewLevel > 1 && (
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot sx={{ bgcolor: dotStyles(2) }} />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Row>
                                                    <small>Request reviewed by {reviews.find((review: { review_level: number; }) => review.review_level === 1)?.reviewer}</small>
                                                </Row>
                                                <Row>
                                                    <small>{formatDateTime(reviews.find((review: { review_level: number; }) => review.review_level === 1)?.reviewed_at)}</small>
                                                </Row>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )}

                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{ bgcolor: dotStyles(3) }} />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Row>
                                                <small>Request approved by {materialRequestData?.material_requests?.approval.approver} </small>
                                            </Row>
                                            <Row>
                                                <small>{formatDateTime(materialRequestData?.material_requests?.approval.reviewed_at)}</small>
                                            </Row>
                                        </TimelineContent>
                                    </TimelineItem>

                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{ bgcolor: dotStyles(4) }} />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Row>
                                                <small>Request paid by {materialRequestData?.material_requests?.paid_by || "pending"} </small>
                                            </Row>
                                            <Row>
                                                <small>{formatDateTime(materialRequestData?.material_requests?.paid_at) || ""}</small>
                                            </Row>
                                        </TimelineContent>
                                    </TimelineItem>

                                </Timeline>
                            </Col>
                        </Row>
                    </Modal.Body>
                </div>
            </Modal>
        </Container>
    );
}

export default ViewMaterialRequest;
