import React, { useState } from "react";
import { Button, Card, Image } from "react-bootstrap";
import Schedule from "../../../assets/images/schedule-icon.png";
import CreateTask from "./modals/create-schedule";
import { useAppSelector } from "../../../store/hooks";
import { hasPermission } from "../../../util/permissionchecker";
import { extractPermissionNames } from '../../../util/transformPermissions';
interface TaskProps {
	handleCreateSuccess: () => void;
}

const NoSchedule: React.FC<TaskProps> = ({ handleCreateSuccess }) => {
	const project_id = localStorage.getItem("projectId");
	const userData = useAppSelector((state: any) => state.user?.userData?.user);
	const isAdmin = userData?.system_role === 'admin';
	const permissions = isAdmin ? null : extractPermissionNames(userData?.permissions?.permissions || []);
	console.log('User permissions:', permissions);
	const [createTaskOpen, setCreateTaskOpen] = useState<boolean>(false);

	const handleOpenCreateTask = () => setCreateTaskOpen(true);
	const handleCloseCreateTask = () => setCreateTaskOpen(false);

	return (
		<div>
			<div className="pt-5 p-3">
				<Card className=" auth-card-3 mt-4 pt-4">
					<div className="text-center ">
						<Image src={Schedule} width={152} height={160} className="my-3" />
						<h3 className="text-color-2">There is no schedule created yet</h3>
						<p className="text-color-2">
							Track and manage the progress of your construction projects
						</p>
						{hasPermission(permissions, "create-schedule") && (
							<Button
								onClick={handleOpenCreateTask}
								className=" my-3 py-3 px-5 border-0 bg-color-1 "
							>
								<span className="text-center">Create schedule</span>
							</Button>
						)}
					</div>
				</Card>
			</div>
			<CreateTask
				project_id={project_id}
				show={createTaskOpen}
				closeModal={handleCloseCreateTask}
				onCreateSuccess={handleCreateSuccess}
			/>
		</div>
	);
};

export default NoSchedule;
