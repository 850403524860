import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";

export interface CompanyProfile {
	id: string;
	company_name: string;
	company_email: string;
	company_size: string;
	company_phone_number: string;
	company_address: string;
	company_state: string;
	company_country: string;
	is_paid: boolean;
	plan: string;
	number_of_users: number;
	company_approver: string;
}
export interface UpdateCompanyProfileData {
	id: string;
	company_id: string;
	company_name: string;
	company_email: string;
	company_phone_number: string;
}
export interface UpdateCompanyProfileResponse {
	data: UpdateCompanyProfileData;
	message: string;
	error: boolean;
}

export interface GetCompanyProfileResponse {
	data: CompanyProfile;
	message: string;
}

export interface UpdateProfileData {
	id: string;
	company_id: string;
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
}
export interface UpdateProfileResponse {
	data: UpdateProfileData;
	message: string;
	error: boolean;
}
export interface Material {
	company_id: string;
	id: string;
	material_name: string;
	material_unit: string;
	material_threshold: string;
	material_status: string;
}
export interface CreateMaterialData {
	company_id: string;
	material_name: string;
	material_unit: string;
	material_threshold: string;
}
export interface CreateMaterialResponse {
	data: Material;
	message: string;
	error: boolean;
}
export interface UpdateMaterialData {
	company_id: string;
	id: string;
	material_name: string;
	material_unit: string;
	material_threshold: string;
	material_status: string;
}
export interface UpdateMaterialResponse {
	data: Material;
	message: string;
	error: boolean;
}
export interface GetAllMaterialsResponse {
	data: Material[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchMaterialResponse {
	data: Material[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface GetAMaterialResponse {
	data: Material;
	message: string;
}
export interface Labour {
	company_id: string;
	labour_type: string;
	id: string;
	labour_description: string;
}
export interface CreateLabourData {
	company_id: string;
	labour_type: string;
	labour_description: string;
}
export interface CreateLabourResponse {
	data: Labour;
	message: string;
	error: boolean;
}
export interface UpdateLabourData {
	company_id: string;
	labour_type: string;
	id: string;
	labour_description: string;
}
export interface UpdateLabourResponse {
	data: Labour;
	message: string;
	error: boolean;
}
export interface GetAllLabourResponse {
	data: Labour[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchLabourResponse {
	data: Labour[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface GetALabourResponse {
	data: Labour;
	message: string;
}
export interface Role {
	company_id: string;
	id: string;
	role_name: string;
	permissions: string[];
}
export interface CreateRoleData {
	company_id: string;
	role_name: string;
	permissions: string[];
}
export interface CreateRoleResponse {
	data: Role;
	message: string;
	error: boolean;
}
export interface UpdateRoleData {
	company_id: string;
	id: string;
	role_name: string;
	permissions: string[];
}
export interface UpdateRoleResponse {
	data: Role;
	message: string;
	error: boolean;
}
export interface GetAllRolesResponse {
	data: Role[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchRoleResponse {
	data: Role[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface GetARoleResponse {
	data: Role;
	message: string;
}

export interface Vendor {
	company_id: string;
	id: string;
	vendor_name: string;
	vendor_email: string;
	vendor_phone_number: string;
	vendor_bank_id: string;
	vendor_bank_name: string;
	vendor_bank_account: string;
}
export interface CreateVendorData {
	company_id: string;
	vendor_name: string;
	vendor_email: string;
	vendor_phone_number: string;
	vendor_bank_id: string;
	vendor_bank_account: string;
}
export interface CreateVendorResponse {
	data: Vendor;
	message: string;
	error: boolean;
}
export interface UpdateVendorData {
	company_id: string;
	id: string;
	vendor_name: string;
	vendor_email: string;
	vendor_phone_number: string;
	vendor_bank_id: string;
	vendor_bank_account: string;
}
export interface UpdateVendorResponse {
	data: Vendor;
	message: string;
	error: boolean;
}
export interface GetAllVendorsResponse {
	data: Vendor[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchVendorResponse {
	data: Vendor[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface GetAVendorResponse {
	data: Vendor;
	message: string;
}
export interface Stage {
	company_id: string;
	stage: string;
	id: string;
}
export interface CreateStageData {
	company_id: string;
	stage: string;
}
export interface CreateStageResponse {
	data: Stage;
	message: string;
	error: boolean;
}
export interface UpdateStageData {
	company_id: string;
	stage: string;
	id: string;
}
export interface UpdateStageResponse {
	data: Stage;
	message: string;
	error: boolean;
}
export interface GetAllStagesResponse {
	data: Stage[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchStageResponse {
	data: Stage[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface GetAStageResponse {
	data: Stage;
	message: string;
}
export const getAllBanks = async () => {
	const response = await axiosInstance.get("/banks");
	return response.data;
};
export const getAllPermissions = async () => {
	const response = await axiosInstance.get("/permissions");
	return response.data;
};

export const getCompanyProfile = async (
	companyId: string,
): Promise<GetCompanyProfileResponse> => {
	const response = await axiosInstance.get<GetCompanyProfileResponse>(
		`/config/company/${companyId}`,
	);
	return response.data;
};

export const updateProfile = async (
	values: UpdateProfileData,
): Promise<UpdateProfileResponse> => {
	const response = await axiosInstance.put<UpdateProfileResponse>(
		`/users/update-admin/${values.id}`,
		values,
	);
	return response.data;
};
export const updateCompanyProfile = async (
	values: UpdateCompanyProfileData,
): Promise<UpdateCompanyProfileResponse> => {
	const response = await axiosInstance.put<UpdateCompanyProfileResponse>(
		`/config/company/update-company/${values.company_id}`,
		values,
	);
	return response.data;
};

export const createMaterial = async (
	values: CreateMaterialData,
): Promise<CreateMaterialResponse> => {
	const response = await axiosInstance.post<CreateMaterialResponse>(
		`/config/materials/create-material?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const updateMaterial = async (
	values: UpdateMaterialData,
	id: string,
): Promise<UpdateMaterialResponse> => {
	const response = await axiosInstance.put<UpdateMaterialResponse>(
		`/config/materials/update-material/${id}?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const getAllMaterials = async (
	companyId: string,
	page: number,
): Promise<GetAllMaterialsResponse> => {
	const response = await axiosInstance.get<GetAllMaterialsResponse>(
		`/config/materials/?company_id=${companyId}&page=${page}`,
	);
	return response.data;
};
export const getAllDropdownMaterials = async (
	companyId: string,
): Promise<GetAllMaterialsResponse> => {
	const response = await axiosInstance.get<GetAllMaterialsResponse>(
		`/config/materials/?company_id=${companyId}&request_type=dropdown`,
	);
	return response.data;
};
export const searchMaterial = async (
	company_id: string,
	searchTerm: string,
	page: number,
): Promise<SearchMaterialResponse> => {
	toast.loading("Searching...");
	try {
		const response = await axiosInstance.get(
			`/config/materials/search/${searchTerm}?company_id=${company_id}&page=${page}`,
		);
		toast.dismiss();
		toast.success("Successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(error.response?.data?.message || "Failed to fetch materials.");

		console.error("Failed to fetch materials:", error);
		throw error;
	}
};
export const getAMaterial = async (
	companyId: string,
	id: string,
): Promise<Material> => {
	const response = await axiosInstance.get<GetAMaterialResponse>(
		`/config/materials/${id}/?company_id=${companyId}`,
	);
	return response.data.data;
};
export const createLabour = async (
	values: CreateLabourData,
): Promise<CreateLabourResponse> => {
	const response = await axiosInstance.post<CreateLabourResponse>(
		`/config/labour-types/create-labour-type?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const updateLabour = async (
	values: UpdateLabourData,
	id: string,
): Promise<UpdateLabourResponse> => {
	const response = await axiosInstance.put<UpdateLabourResponse>(
		`/config/labour-types/update-labour-type/${id}?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const getAllLabour = async (
	companyId: string,
	page: number,
): Promise<GetAllLabourResponse> => {
	const response = await axiosInstance.get<GetAllLabourResponse>(
		`/config/labour-types/?company_id=${companyId}&page=${page}`,
	);
	return response.data;
};
export const getAllDropdownLabour = async (
	companyId: string,
): Promise<GetAllLabourResponse> => {
	const response = await axiosInstance.get<GetAllLabourResponse>(
		`/config/labour-types/?company_id=${companyId}&request_type=dropdown`,
	);
	return response.data;
};
export const searchLabour = async (
	company_id: string,
	searchTerm: string,
	page: number,
): Promise<SearchLabourResponse> => {
	toast.loading("Searching...");
	try {
		const response = await axiosInstance.get(
			`/config/labour-types/search/${searchTerm}?company_id=${company_id}&page=${page}`,
		);
		toast.dismiss();
		toast.success("Search successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(error.response?.data?.message || "Failed to fetch labours.");

		console.error("Failed to fetch labours:", error);
		throw error;
	}
};
export const getALabour = async (
	companyId: string,
	id: string,
): Promise<Labour> => {
	const response = await axiosInstance.get<GetALabourResponse>(
		`/config/labour-types/${id}/?company_id=${companyId}`,
	);
	return response.data.data;
};
export const createRole = async (
	values: CreateRoleData,
): Promise<CreateRoleResponse> => {
	const response = await axiosInstance.post<CreateRoleResponse>(
		`/config/roles/create-role?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const updateRole = async (
	values: UpdateRoleData,
	id: string,
): Promise<UpdateRoleResponse> => {
	const response = await axiosInstance.put<UpdateRoleResponse>(
		`/config/roles/update-role/${id}?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const getAllRoles = async (
	companyId: string,
	page: number,
): Promise<GetAllRolesResponse> => {
	const response = await axiosInstance.get<GetAllRolesResponse>(
		`/config/roles/?company_id=${companyId}&page=${page}`,
	);
	return response.data;
};
export const getAllDropdownRoles = async (
	companyId: string,
): Promise<GetAllRolesResponse> => {
	const response = await axiosInstance.get<GetAllRolesResponse>(
		`/config/roles/?company_id=${companyId}&request_type=dropdown`,
	);
	return response.data;
};
export const searchRole = async (
	company_id: string,
	searchTerm: string,
	page: number,
): Promise<SearchRoleResponse> => {
	toast.loading("Searching...");
	try {
		const response = await axiosInstance.get(
			`/config/roles/search/${searchTerm}?company_id=${company_id}&page=${page}`,
		);
		toast.dismiss();
		toast.success("Search successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(error.response?.data?.message || "Failed to fetch roles.");

		console.error("Failed to fetch roles:", error);
		throw error;
	}
};
export const getARole = async (
	companyId: string,
	id: string,
): Promise<Role> => {
	const response = await axiosInstance.get<GetARoleResponse>(
		`/config/roles/${id}/?company_id=${companyId}`,
	);
	return response.data.data;
};
export const createVendor = async (
	values: CreateVendorData,
): Promise<CreateVendorResponse> => {
	const response = await axiosInstance.post<CreateVendorResponse>(
		`/config/vendors/create-vendor?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const updateVendor = async (
	values: UpdateVendorData,
	id: string,
): Promise<UpdateVendorResponse> => {
	const response = await axiosInstance.put<UpdateVendorResponse>(
		`/config/vendors/update-vendor/${id}?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const getAllVendors = async (
	companyId: string,
	page: number,
): Promise<GetAllVendorsResponse> => {
	const response = await axiosInstance.get<GetAllVendorsResponse>(
		`/config/vendors/?company_id=${companyId}&page=${page}`,
	);
	return response.data;
};
export const getAllDropdownVendors = async (
	companyId: string,
): Promise<GetAllVendorsResponse> => {
	const response = await axiosInstance.get<GetAllVendorsResponse>(
		`/config/vendors/?company_id=${companyId}&request_type=dropdown`,
	);
	return response.data;
};
export const searchVendor = async (
	company_id: string,
	searchTerm: string,
	page: number,
): Promise<SearchVendorResponse> => {
	toast.loading("Searching...");
	try {
		const response = await axiosInstance.get(
			`/config/vendors/search/${searchTerm}?company_id=${company_id}&page=${page}`,
		);
		toast.dismiss();
		toast.success("Search successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(error.response?.data?.message || "Failed to fetch vendors.");

		console.error("Failed to fetch vendors:", error);
		throw error;
	}
};
export const getAVendor = async (
	companyId: string,
	id: string,
): Promise<Vendor> => {
	const response = await axiosInstance.get<GetAVendorResponse>(
		`/config/vendors/${id}/?company_id=${companyId}`,
	);
	return response.data.data;
};
export const createStage = async (
	values: CreateStageData,
): Promise<CreateStageResponse> => {
	const response = await axiosInstance.post<CreateStageResponse>(
		`/config/stages/create-stage?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const updateStage = async (
	values: UpdateStageData,
	id: string,
): Promise<UpdateStageResponse> => {
	const response = await axiosInstance.put<UpdateStageResponse>(
		`/config/stages/update-stage/${id}?company_id=${values.company_id}`,
		values,
	);
	return response.data;
};
export const getAllStages = async (
	companyId: string,
	page: number,
): Promise<GetAllStagesResponse> => {
	const response = await axiosInstance.get<GetAllStagesResponse>(
		`/config/stages/?company_id=${companyId}&page=${page}`,
	);
	return response.data;
};
export const getAllDropdownStages = async (
	companyId: string,
): Promise<GetAllStagesResponse> => {
	const response = await axiosInstance.get<GetAllStagesResponse>(
		`/config/stages/?company_id=${companyId}&request_type=dropdown`,
	);
	return response.data;
};
export const searchStage = async (
	company_id: string,
	searchTerm: string,
	page: number,
): Promise<SearchStageResponse> => {
	toast.loading("Searching..");
	try {
		const response = await axiosInstance.get<SearchStageResponse>(
			`/config/stages/search/${searchTerm}?company_id=${company_id}&page=${page}`,
		);
		toast.dismiss();
		toast.success("Search successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(
			error.response?.data?.message || "Failed to fetch material requests.",
		);

		console.error("Failed to fetch material requests:", error);
		throw error;
	}
};
export const getAStage = async (
	companyId: string,
	id: string,
): Promise<Stage> => {
	const response = await axiosInstance.get<GetAStageResponse>(
		`/config/stages/${id}/?company_id=${companyId}`,
	);
	return response.data.data;
};
