import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Container, Table, Button } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import {
    getAPettyRequestAsync,
    setPettyRequestId,
} from '../../../../slices/requests/requestsSlice';
import ConfirmPayUtilityRequest from "./confirm-pay-utility-request";
import { RootState } from "../../../../store/store";
import { format, isValid } from 'date-fns';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
interface PayUtilityRequestProps {
    closeModal: () => void;
    show: boolean;
    pettyRequestId: string;
    projectId: string;
}

const PayUtilityRequest: React.FC<PayUtilityRequestProps> = ({ closeModal, show, pettyRequestId, projectId }) => {
    const formatCurrency = (amount: number): string => {
        return `₦${amount.toLocaleString('en-US')}`;
    };
    const formatDateTime = (dateTime: string | Date): string => {
        if (!dateTime) return "Invalid Date";
        const date = new Date(dateTime);
        return isValid(date) ? format(date, "dd/MM/yy h:mm a") : "Invalid Date";
    };
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(
        (state: RootState) => state.auth.user?.company_id || "",
    );
    const pettyRequestData = useAppSelector((state: any) => state.requests.pettyRequestData) || {};
    const [loading, setLoading] = useState(true);
    const [selectedPettyRequestId, setSelectedPettyRequestId] = useState<string | null>(null);
    const [confirmPayUtilityRequestModalOpen, setConfirmPayUtilityRequestModalOpen] = useState<boolean>(false);
    const handleOpenConfirmPayUtilityRequestModal = (pettyRequestId: string) => {
        setSelectedPettyRequestId(pettyRequestId);
        setConfirmPayUtilityRequestModalOpen(true);
        closeModal();
    };

    const handleCloseConfirmPayUtilityRequestModal = () => setConfirmPayUtilityRequestModalOpen(false);


    useEffect(() => {
        if (pettyRequestId) {
            dispatch(setPettyRequestId(pettyRequestId));
            console.log("Fetching petty cash request with ID:", pettyRequestId);
            dispatch(getAPettyRequestAsync({ companyId, id: pettyRequestId, projectId })).then(() =>
                setLoading(false),
            );
        } else {
            console.log("pettyRequestId is not set");
        }
    }, [dispatch, companyId, pettyRequestId, projectId]);

    if (loading) {
        return null;
    }
    const reviewLevel = pettyRequestData?.petty_cash_requests?.review_level;
    const reviews = pettyRequestData?.petty_cash_requests?.reviews;

    const dotStyles = (step: number) => {
        const status = pettyRequestData?.petty_cash_requests?.status;
        const payment_status = pettyRequestData?.petty_cash_requests?.payment_status; if (step === 1 && status === 'approved') return '#0f6cbd';
        if (step === 2 && status === 'reviewed') return '#0f6cbd';
        if (step === 3 && status === 'approved') return '#0f6cbd';
        if (step === 4) {
            if (payment_status === 'paid') return '#0f6cbd';
            if (payment_status === 'unpaid') return '#ccc';
        }
        return '#ccc';
    };
    console.log("pettyRequestData", pettyRequestData);

    return (
        <Container>
            <Modal centered size="xl" show={show} onHide={closeModal} style={{ maxHeight: "100vh", overflowY: "auto" }}>
                <div className='px-3'>
                    <Modal.Header closeButton className='text-start'>
                        <h4 className="confirm-header">Pay petty cash request</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-2 pb-2'>
                            <Col md={8} className="col-separator">
                                <Table className="rectangle" borderless responsive >
                                    <thead >
                                        <tr>
                                            <td className='text-color-7'>Title </td>
                                            <td className='text-color-7'>Receiver name </td>
                                            <td className='text-color-7'>Amount </td>
                                            <td className='text-color-7'>Bank </td>
                                            <td className='text-color-7'>Acct no </td>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={pettyRequestData?.petty_cash_requests?.id}>
                                            <td className='text-color-7'>{pettyRequestData?.petty_cash_requests?.title} </td>
                                            <td className='text-color-7'>{pettyRequestData?.petty_cash_requests?.receiver_name}</td>
                                            <td className='text-color-7'>{formatCurrency(parseFloat(pettyRequestData?.petty_cash_requests?.amount))}</td>
                                            <td className='text-color-7'>{pettyRequestData?.petty_cash_requests?.bank}</td>
                                            <td className='text-color-7'>{pettyRequestData?.petty_cash_requests?.account_number}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={4}>
                                <Timeline
                                    placeholder="Timeline"
                                    onPointerEnterCapture={() => { }}
                                    onPointerLeaveCapture={() => { }}
                                    sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{ bgcolor: dotStyles(1) }} />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Row>
                                                <small>Request initiated by {pettyRequestData?.petty_cash_requests?.created_by} </small>
                                            </Row>
                                            <Row>
                                                <small>{formatDateTime(pettyRequestData?.petty_cash_requests?.created_on)}</small>
                                            </Row>
                                        </TimelineContent>
                                    </TimelineItem>
                                    {reviewLevel > 1 && (
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot sx={{ bgcolor: dotStyles(2) }} />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Row>
                                                    <small>Request reviewed by {reviews.find((review: { review_level: number; }) => review.review_level === 1)?.reviewer}</small>
                                                </Row>
                                                <Row>
                                                    <small>{formatDateTime(reviews.find((review: { review_level: number; }) => review.review_level === 1)?.reviewed_at)}</small>
                                                </Row>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )}

                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{ bgcolor: dotStyles(3) }} />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Row>
                                                <small>Request approved by {pettyRequestData?.petty_cash_requests?.approval.approver} </small>
                                            </Row>
                                            <Row>
                                                <small>{formatDateTime(pettyRequestData?.petty_cash_requests?.approval.reviewed_at)}</small>
                                            </Row>
                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot sx={{ bgcolor: dotStyles(4) }} />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Row>
                                                <small>Request paid by {pettyRequestData?.petty_cash_requests?.paid_by || "pending"} </small>
                                            </Row>
                                            <Row>
                                                <small>{formatDateTime(pettyRequestData?.petty_cash_requests?.paid_at)}</small>
                                            </Row>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </Col>
                        </Row>
                        <Modal.Footer className='mt-3'>
                            <Row >
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button onClick={closeModal} className='cancel-btn'>
                                            <span className="text-center">Cancel </span>                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className=' mt-1'>
                                        <Button type='submit' className=' border-0 create-btn bg-color-1' onClick={() => handleOpenConfirmPayUtilityRequestModal(pettyRequestId)}>
                                            <span className="text-center">Pay </span>                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal.Body>
                </div>
            </Modal>
            <ConfirmPayUtilityRequest show={confirmPayUtilityRequestModalOpen} closeModal={handleCloseConfirmPayUtilityRequestModal} projectId={projectId!} pettyRequestId={selectedPettyRequestId!} />
        </Container>
    )
}

export default PayUtilityRequest