import React, { useState, useEffect } from 'react';
import { Image } from "react-bootstrap";
import ProfileVector from "../../assets/images/profile-vector.png";
import TeamVector from "../../assets/images/team-vector.png";
import ApprovalVector from "../../assets/images/approval-vector.png";
import MaterialVector from "../../assets/images/material-vector.png";
import VendorVector from "../../assets/images/vendor-vector.png";
import StageVector from "../../assets/images/stages-vector.png"
import BillingVector from "../../assets/images/billings-vector.png";
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

function SettingsSideBar() {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
    const location = useLocation();
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1320) {
                setIsCollapsed(true);
                setIsSidebarOpen(false);
            } else {
                setIsCollapsed(false);
                setIsSidebarOpen(true);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };
    return (
        <>
            {isCollapsed && (
                <button className="settings-hamburger" onClick={toggleSidebar}>
                    <MenuIcon />
                </button>
            )}
            <aside
                className={`sidebar bg-color-9 pt-5 px-3 ${isSidebarOpen ? 'open' : 'closed'}`}
            >                <h3 className='fw-bold'>Settings</h3>
                <div className=' sidebar-menu pt-5'>
                    <Link to="/settings-profile" className={`sidebar-link ${location.pathname === '/settings-profile' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={ProfileVector} className='sidebar-icon' />
                            Profile
                        </span>
                    </Link>
                    <Link to="/settings-team" className={`sidebar-link ${location.pathname === '/settings-team' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={TeamVector} className='sidebar-icon' />
                            Team
                        </span>
                    </Link>
                    <Link to="/settings-approval-level" className={`sidebar-link ${location.pathname === '/settings-approval-level' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={ApprovalVector} className='sidebar-icon' />
                            Approval level
                        </span>
                    </Link>
                    <Link to="/settings-materials" className={`sidebar-link ${location.pathname === '/settings-materials' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={MaterialVector} className='sidebar-icon' />
                            Materials
                        </span>
                    </Link>
                    <Link to="/settings-vendors" className={`sidebar-link ${location.pathname === '/settings-vendors' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={VendorVector} className='sidebar-icon' />
                            Vendors
                        </span>
                    </Link>
                    <Link to="/settings-stages" className={`sidebar-link ${location.pathname === '/settings-stages' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={StageVector} className='sidebar-icon' />
                            Stages
                        </span>
                    </Link>
                    <Link to="/settings-billings" className={`sidebar-link ${location.pathname === '/settings-billings' ? 'active' : ''}`}>
                        <span className='sidebar-text sidebar-link'>
                            <Image src={BillingVector} className='sidebar-icon' />
                            Billings
                        </span>
                    </Link>

                </div>
            </aside>
        </>
    )
}

export default SettingsSideBar