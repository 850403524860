import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify";

export interface ApprovedMaterialRequests {
	company_id: string;
	created_on: string;
	vendor_id: string;
	vendor_name: string;
	bank_name: string;
	paid_at: string;
	paid_by: string;
	account_number: string;
	material_id: string;
	total_quantity: string;
	batch_materials: string;
	materials: {
		material_name: string;
		material_id: string;
		quantity: string;
		unit_cost: string;
	}[];
	quantity: string;
	amount: string;
	payment_status: string;
	projectId: string;
	id: string;
}
export interface SearchApprovedMaterialRequests {
	company_id: string;
	created_on: string;
	vendor_id: string;
	vendor_name: string;
	bank_name: string;
	paid_at: string;
	paid_by: string;
	account_number: string;
	material_id: string;
	total_quantity: string;
	batch_materials: string;
	materials: {
		material_name: string;
		material_id: string;
		quantity: string;
		unit_cost: string;
	}[];
	quantity: string;
	amount: string;
	payment_status: string;
	projectId: string;
	id: string;
}
export interface PayApprovedMaterialRequestsData {
	company_id: string;
	projectId: string;
	id: string;
}
export interface PayApprovedMaterialRequestsResponse {
	data: ApprovedMaterialRequests;
	message: string;
}
export interface GetAllApprovedMaterialRequestsResponse {
	data: ApprovedMaterialRequests[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchApprovedMaterialRequestsResponse {
	data: ApprovedMaterialRequests[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface ApprovedLabourRequest {
	company_id: string;
	created_on: string;
	head_name: string;
	stage: string;
	stage_id: string;
	labour_type: string;
	labour_type_id: string;
	bank: string;
	bank_id: string;
	account_number: string;
	amount: string;
	payment_status: string;
	paid_at: string;
	paid_by: string;
	projectId: string;
	id: string;
}
export interface SearchLabourRequest {
	company_id: string;
	created_on: string;
	head_name: string;
	stage: string;
	stage_id: string;
	labour_type: string;
	labour_type_id: string;
	bank: string;
	bank_id: string;
	account_number: string;
	amount: string;
	payment_status: string;
	paid_at: string;
	paid_by: string;
	projectId: string;
	id: string;
}
export interface PayApprovedLabourRequestsData {
	company_id: string;
	projectId: string;
	id: string;
}
export interface PayApprovedLabourRequestsResponse {
	data: ApprovedLabourRequest;
	message: string;
}
export interface GetAllApprovedLabourRequestsResponse {
	data: ApprovedLabourRequest[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchApprovedLabourRequestsResponse {
	data: ApprovedLabourRequest[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface ApprovedPettyRequest {
	company_id: string;
	created_on: string;
	title: string;
	receiver_name: string;
	bank_id: string;
	account_number: string;
	amount: string;
	payment_status: string;
	paid_at: string | null;
	paid_by: string | null;
	projectId: string;
	id: string;
}
export interface PayApprovedPettyRequestsData {
	company_id: string;
	projectId: string;
	id: string;
}
export interface PayApprovedPettyRequestsResponse {
	data: ApprovedPettyRequest;
	message: string;
}
export interface GetAllApprovedPettyRequestsResponse {
	data: ApprovedPettyRequest[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface SearchApprovedPettyRequestsResponse {
	data: ApprovedPettyRequest[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}

export const getAllApprovedMaterialRequests = async (
	companyId: string,
	projectId: string,
	page: number,
): Promise<GetAllApprovedMaterialRequestsResponse> => {
	const response =
		await axiosInstance.get<GetAllApprovedMaterialRequestsResponse>(
			`/projects/${projectId}/material-requests/approved?company_id=${companyId}&page=${page}`,
		);
	return response.data;
};

export const searchApprovedMaterialRequests = async (
	companyId: string,
	projectId: string,
	page: number,
	searchTerm: string,
	search_type: string,
): Promise<SearchApprovedMaterialRequestsResponse> => {
	toast.loading("Searching...");

	try {
		const response =
			await axiosInstance.get<SearchApprovedMaterialRequestsResponse>(
				`/projects/${projectId}/material-requests/search/${searchTerm}?company_id=${companyId}&search_type=${search_type}&page=${page}`,
			);
		toast.dismiss();
		toast.success("Search Successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(
			error.response?.data?.message || "Failed to fetch material requests.",
		);
		console.error("Failed to fetch material requests:", error);
		throw error;
	}
};

export const payApprovedMaterialRequest = async (
	values: PayApprovedMaterialRequestsData,
): Promise<PayApprovedMaterialRequestsResponse> => {
	const response = await axiosInstance.put<PayApprovedMaterialRequestsResponse>(
		`/projects/${values.projectId}/material-requests/${values.id}/pay`,
		values,
	);
	return response.data;
};

export const getAllApprovedLabourRequests = async (
	companyId: string,
	projectId: string,
	page: number,
): Promise<GetAllApprovedLabourRequestsResponse> => {
	const response =
		await axiosInstance.get<GetAllApprovedLabourRequestsResponse>(
			`/projects/${projectId}/labour-requests/approved`,
			{
				params: { company_id: companyId, page },
			},
		);
	return response.data;
};
export const searchApprovedLabourRequests = async (
	companyId: string,
	projectId: string,
	page: number,
	searchTerm: string,
	search_type: string,
): Promise<SearchApprovedLabourRequestsResponse> => {
	toast.loading("Searching...");
	try {
		const response =
			await axiosInstance.get<SearchApprovedLabourRequestsResponse>(
				`/projects/${projectId}/labour-requests/search/${searchTerm}?company_id=${companyId}}&search_type=${search_type}&page=${page}`,
			);
		toast.dismiss();
		toast.success("Search successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(
			error.response?.data?.message || "Failed to fetch material requests.",
		);
		console.error("Failed to fetch material requests:", error);
		throw error;
	}
};

export const payApprovedLabourRequest = async (
	values: PayApprovedLabourRequestsData,
): Promise<PayApprovedLabourRequestsResponse> => {
	const response = await axiosInstance.put<PayApprovedLabourRequestsResponse>(
		`/projects/${values.projectId}/labour-requests/${values.id}/pay`,
		values,
	);
	return response.data;
};

export const getAllApprovedPettyRequests = async (
	companyId: string,
	projectId: string,
	page: number,
): Promise<GetAllApprovedPettyRequestsResponse> => {
	const response = await axiosInstance.get<GetAllApprovedPettyRequestsResponse>(
		`/projects/${projectId}/petty-cash-requests/approved`,
		{
			params: { company_id: companyId, page },
		},
	);
	return response.data;
};

export const searchApprovedPettyRequests = async (
	companyId: string,
	projectId: string,
	page: number,
	searchTerm: string,
	search_type: string,
): Promise<SearchApprovedPettyRequestsResponse> => {
	toast.loading("Searching...");

	try {
		const response =
			await axiosInstance.get<SearchApprovedPettyRequestsResponse>(
				`/projects/${projectId}/petty-cash-requests/search/${searchTerm}?company_id=${companyId}&search_type=${search_type}&page=${page}`,
			);
		toast.dismiss();
		toast.success("Search successful");
		return response.data;
	} catch (error: any) {
		toast.dismiss();
		toast.error(
			error.response?.data?.message || "Failed to fetch material requests.",
		);

		console.error("Failed to fetch material requests:", error);
		throw error;
	}
};

export const payApprovedPettyRequest = async (
	values: PayApprovedPettyRequestsData,
): Promise<PayApprovedPettyRequestsResponse> => {
	const response = await axiosInstance.put<PayApprovedPettyRequestsResponse>(
		`/projects/${values.projectId}/petty-cash-requests/${values.id}/pay`,
		values,
	);
	return response.data;
};
