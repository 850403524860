/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import SettingsLayout from "../settings-layout";
import { Nav, Table, Button, Row, Col, Dropdown } from "react-bootstrap";
import CreateMaterial from "./modals/create-material";
import EditMaterial from "./modals/edit-material";
import Action from "../../../assets/images/action-vector.png";
import searchIcon from "../../../assets/images/search-icon.svg";
import CreateLabour from "./modals/create-labour";
import EditLabour from "./modals/edit-labour";
import {
	getAllMaterialsAsync,
	getAllLabourAsync,
	searchMaterialAsync,
	searchLabourAsync,
} from "../../../slices/settings/settingsSlice";
import { getUserAsync } from "../../../slices/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import CloseButton from "../../../components/close-button/close-button";
import Pagination from "@mui/material/Pagination";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

function Materials() {
	const dispatch = useAppDispatch();
	const materials = useAppSelector((state) => state.settings.materials?.data);
	const labourTypes = useAppSelector(
		(state) => state.settings.labourTypes?.data,
	);
	const materialMeta = useAppSelector(
		(state) => state.settings.materials?.meta,
	);
	const labourMeta = useAppSelector(
		(state) => state.settings.labourTypes?.meta,
	);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);
	const [activeTab, setActiveTab] = useState<
		"materials" | "labour-types"
	>("materials");

	const handleTabSelect = (eventKey: string | null) => {
		if (eventKey) {
			setActiveTab(eventKey as "materials" | "labour-types");
		}
	};

	const [searchMaterialQuery, setSearchMaterialQuery] = useState<string>("");
	const [searchLabourQuery, setSearchLabourQuery] = useState<string>("");

	const [modals, setModals] = useState({
		createMaterial: false,
		editMaterial: null,
		createLabour: false,
		editLabour: null,
	});

	const toggleModal = (modal: any, value: any) => {
		setModals((prevModals) => ({
			...prevModals,
			[modal]: value,
		}));
	};
	const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>(
		{},
	);
	const [labourDropdownOpen, setLabourDropdownOpen] = useState<{
		[key: number]: boolean;
	}>({});

	const toggleDropdown = (index: number) => {
		setDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const toggleLabourDropdown = (index: number) => {
		setLabourDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const [refreshData, setRefreshData] = useState(false);
	const [pageNumber, setPage] = useState(1);
	const [materialsPage, setMaterialPage] = useState(1);
	const [labourPage, setLabourPage] = useState(1);

	const [pageNumbers, setPageNumbers] = useState({
		"materials": 1,
		"labour-types": 1,
	});
	useEffect(() => {
		setPageNumbers((prevState) => ({
			...prevState,
			[activeTab]: 1,
		}));
	}, [activeTab]);

	const handleMaterialChange = (
		event: React.ChangeEvent<unknown>,
		newPage: number,
	) => {
		setPageNumbers((prevState) => ({
			...prevState,
			"materials": newPage,
		}));
		setMaterialPage(newPage);
		if (searchMaterialQuery.trim() === "") {
			dispatch(
				getAllMaterialsAsync({
					companyId,
					page: newPage,
				}),
			);
		} else {
			dispatch(
				searchMaterialAsync({
					company_id: companyId,
					searchTerm: searchMaterialQuery,
					page: 1,
				}),
			);
		}
	};
	const handleLabourChange = (
		event: React.ChangeEvent<unknown>,
		newPage: number,
	) => {
		setPageNumbers((prevState) => ({
			...prevState,
			"labour-types": newPage,
		}));
		setLabourPage(newPage);
		if (searchLabourQuery.trim() === "") {
			dispatch(
				getAllLabourAsync({
					companyId,
					page: newPage,
				}),
			);
		} else {
			dispatch(
				searchLabourAsync({
					company_id: companyId,
					searchTerm: searchLabourQuery,
					page: 1,
				}),
			);
		}
	};

	useEffect(() => {
		if (activeTab === "materials") {
			setMaterialPage(1);
		} else if (activeTab === "labour-types") {
			setLabourPage(1);
		}
	}, [activeTab]);

	useEffect(() => {
		if (companyId) {
			const currentPage = pageNumbers[activeTab];
			if (activeTab === "materials") {
				dispatch(getAllMaterialsAsync({ companyId, page: currentPage }));
			} else {
				dispatch(getAllLabourAsync({ companyId, page: currentPage }));
			}
		}
	}, [companyId, dispatch, pageNumbers, activeTab]);

	const handleCreateSuccess = () => {
		setRefreshData(true);
	};
	const handleEditSuccess = () => {
		setRefreshData(true);
	};

	console.log("Materials:", materials);
	console.log("LabourTypes:", labourTypes);

	return (
		<div className="bg-color-8">
			<SettingsLayout>
				<div className="pt-5 p-5">
					<div className="w-100 mb-5 d-inline-flex justify-content-between align-items-center">
						<h4 className="text-color-2">Materials & Labour types</h4>
						<CloseButton />
					</div>

					<Nav
						activeKey={activeTab}
						onSelect={handleTabSelect}
						className="nav px-2 py-2"
					>
						<Nav.Item>
							<Nav.Link
								className=" px-2 py-0 rounded fw-light mx-2"
								eventKey="materials"
								style={{
									textDecoration: "none",
									color: activeTab === "materials" ? " #FFFFFF " : " #747487",
									backgroundColor:
										activeTab === "materials" ? "#0F6CBD" : "transparent",
								}}
							>
								Materials
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								className=" px-2 py-0 rounded fw-light mx-2"
								eventKey="labour-types"
								style={{
									color:
										activeTab === "labour-types" ? " #FFFFFF " : " #747487",
									textDecoration: "none",
									backgroundColor:
										activeTab === "labour-types" ? "#0F6CBD" : "transparent",
								}}
							>
								Labour types
							</Nav.Link>
						</Nav.Item>
					</Nav>

					<div>
						{activeTab === "materials" && (
							<div className="bg-color-9 px-5 pt-3 mt-4">
								<Row className="w-100 justify-content-end align-items-center mb-3">
									<Col md={6} sm={6} xs={12} className="mt-2">
										<Formik
											initialValues={{ searchMaterialQuery: "" }}
											validationSchema={Yup.object({
												searchMaterialQuery: Yup.string(),
											})}
											onSubmit={async (
												values,
												{ setSubmitting, setErrors },
											) => {
												try {
													setSubmitting(true);
													setSearchMaterialQuery(values.searchMaterialQuery);
													setPage(1);
													if (values.searchMaterialQuery.trim() === "") {
														await dispatch(
															getAllMaterialsAsync({
																companyId,
																page: 1,
															}),
														);
													} else {
														await dispatch(
															searchMaterialAsync({
																company_id: companyId,
																searchTerm: values.searchMaterialQuery,
																page: 1,
															}),
														);
													}
												} catch (error) {
													setErrors({
														searchMaterialQuery:
															"An error occurred. Please try again.",
													});
												} finally {
													setSubmitting(false);
												}
											}}
										>
											{({ isSubmitting, handleSubmit, errors, touched }) => (
												<Form
													onSubmit={handleSubmit}
													className="d-flex align-items-center"
												>
													{" "}
													<div className="border border-1 rounded d-flex align-items-center">
														<Field
															type="search"
															name="searchMaterialQuery"
															placeholder="Search for material"
															className="form-control border-0"
														/>
														<button
															type="submit"
															className=""
															disabled={isSubmitting}
															style={{
																background: "none",
																border: "none",
																boxShadow: "none",
															}}
														>
															<img src={searchIcon} alt="search icon" />
														</button>
													</div>
													<ErrorMessage
														name="searchMaterialQuery"
														component="div"
														className="text-danger ms-3"
													/>
												</Form>
											)}
										</Formik>
									</Col>
									<Col md={6} sm={6} xs={12} className="mt-2">
										<Button
											onClick={() => toggleModal("createMaterial", true)}
											className="py-3 px-5 border-0 bg-color-1 float-end"
										>
											<span className="text-center">Add material</span>
										</Button>
									</Col>
								</Row>

								<Table borderless responsive>
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Material name</th>
											<th className="text-color-1 bg-color-3">
												Unit of measurement
											</th>
											<th className="text-color-1 bg-color-3">
												Material threshold
											</th>
											<th className="text-color-1 bg-color-3">Action</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(materials) && materials.length > 0 ? (
											materials.map((material, index) => (
												<tr key={material.id}>
													<td>{index + 1}</td>
													<td>{material.material_name}</td>
													<td>{material.material_unit}</td>
													<td>{material.material_threshold}</td>
													<td style={{ padding: "0", verticalAlign: "middle" }}>
														<Dropdown
															show={dropdownOpen[index]}
															onToggle={() => toggleDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon mb-3"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		toggleModal("editMaterial", material.id)
																	}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={6} className="text-center">
													No materials found.
												</td>
											</tr>
										)}
									</tbody>
								</Table>
								{materialMeta && materialMeta.total_pages > 1 && (
									<div className="d-flex justify-content-center mt-3 pb-5">
										<Pagination
											count={materialMeta && materialMeta?.total_pages}
											page={materialsPage}
											onChange={handleMaterialChange}
											shape="rounded"
											color="primary"
										/>
									</div>
								)}
							</div>
						)}
					</div>
					<div>
						{activeTab === "labour-types" && (
							<div className="bg-color-9 px-5 pt-3 mt-4">
								<Row className="w-100 justify-content-end align-items-center mb-3">
									<Col md={6} sm={6} xs={12} className="mt-2">
										<Formik
											initialValues={{ searchLabourQuery: "" }}
											validationSchema={Yup.object({
												searchLabourQuery: Yup.string(),
											})}
											onSubmit={async (
												values,
												{ setSubmitting, setErrors },
											) => {
												try {
													setSubmitting(true);
													setSearchLabourQuery(values.searchLabourQuery);
													setPage(1);
													if (values.searchLabourQuery.trim() === "") {
														await dispatch(
															getAllLabourAsync({
																companyId,
																page: 1,
															}),
														);
													} else {
														await dispatch(
															searchLabourAsync({
																company_id: companyId,
																searchTerm: values.searchLabourQuery,
																page: 1,
															}),
														);
													}
												} catch (error) {
													setErrors({
														searchLabourQuery:
															"An error occurred. Please try again.",
													});
												} finally {
													setSubmitting(false);
												}
											}}
										>
											{({ isSubmitting, handleSubmit, errors, touched }) => (
												<Form
													onSubmit={handleSubmit}
													className="d-flex align-items-center"
												>
													{" "}
													<div className="border border-1 rounded d-flex align-items-center">
														<Field
															type="search"
															name="searchLabourQuery"
															placeholder="Search for labour type"
															className="form-control border-0"
														/>
														<button
															type="submit"
															className=""
															disabled={isSubmitting}
															style={{
																background: "none",
																border: "none",
																boxShadow: "none",
															}}
														>
															<img src={searchIcon} alt="search icon" />
														</button>
													</div>
													<ErrorMessage
														name="searchLabourQuery"
														component="div"
														className="text-danger ms-3"
													/>
												</Form>
											)}
										</Formik>
									</Col>
									<Col md={6} sm={6} xs={12} className="mt-2">
										<Button
											onClick={() => toggleModal("createLabour", true)}
											className="py-3 px-5 border-0 bg-color-1 float-end"
										>
											<span className="text-center">Add labour</span>
										</Button>
									</Col>
								</Row>
								<Table borderless responsive>
									<thead>
										<tr>
											<th className="text-color-1 bg-color-3">S/N</th>
											<th className="text-color-1 bg-color-3">Labour type</th>
											<th className="text-color-1 bg-color-3">Description</th>
											<th className="text-color-1 bg-color-3">Action</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(labourTypes) && labourTypes.length > 0 ? (
											labourTypes.map((labour, index) => (
												<tr key={labour.id}>
													<td>{index + 1}</td>
													<td>{labour.labour_type}</td>
													<td>{labour.labour_description}</td>
													<td style={{ padding: "0", verticalAlign: "middle" }}>
														<Dropdown
															show={labourDropdownOpen[index]}
															onToggle={() => toggleLabourDropdown(index)}
														>
															<Dropdown.Toggle
																className="dropdown-toggle"
																variant="transparent"
																style={{
																	background: "none",
																	border: "none",
																	boxShadow: "none",
																}}
															>
																<img
																	src={Action}
																	alt="Action"
																	className="action-icon mb-3"
																	style={{ margin: "0", padding: "0" }}
																/>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item
																	onClick={() =>
																		toggleModal("editLabour", labour.id)
																	}
																>
																	Edit
																</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan={4} className="text-center">
													No labour types found.
												</td>
											</tr>
										)}
									</tbody>
								</Table>
								{labourMeta && labourMeta.total_pages > 1 && (
									<div className="d-flex justify-content-center mt-3 pb-5">
										<Pagination
											count={labourMeta && labourMeta?.total_pages}
											page={labourPage}
											onChange={handleLabourChange}
											shape="rounded"
											color="primary"
										/>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
				<CreateMaterial
					show={modals.createMaterial}
					closeModal={() => toggleModal("createMaterial", false)}
					onCreateSuccess={handleCreateSuccess}
				/>

				{modals.editMaterial && (
					<EditMaterial
						show={!!modals.editMaterial}
						closeModal={() => toggleModal("editMaterial", null)}
						materialId={modals.editMaterial}
						onEditSuccess={handleEditSuccess}
					/>
				)}

				<CreateLabour
					show={modals.createLabour}
					closeModal={() => toggleModal("createLabour", false)}
					onCreateSuccess={handleCreateSuccess}
				/>

				{modals.editLabour && (
					<EditLabour
						show={!!modals.editLabour}
						closeModal={() => toggleModal("editLabour", null)}
						labourId={modals.editLabour}
						onEditSuccess={handleEditSuccess}
					/>
				)}
			</SettingsLayout>
		</div>
	);
}

export default Materials;
