import React, { useState, useEffect } from "react";
import SettingsLayout from "../settings-layout";
import { Table, Button, Row, Col, Dropdown } from "react-bootstrap";
import Action from "../../../assets/images/action-vector.png";
import searchIcon from "../../../assets/images/search-icon.svg";
import CreateStage from "./modals/create-stage";
import EditStage from "./modals/edit-stage";
import CloseButton from "../../../components/close-button/close-button";
import {
	getAllStagesAsync,
	searchStageAsync,
} from "../../../slices/settings/settingsSlice";
import { getUserAsync } from "../../../slices/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import Pagination from "@mui/material/Pagination";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";


function Stages() {
	const dispatch = useAppDispatch();
	const stages = useAppSelector((state) => state.settings.stages?.data);
	const meta = useAppSelector((state) => state.settings.stages?.meta);
	const companyId = useAppSelector(
		(state: RootState) => state.auth.user?.company_id || "",
	);
	const [selectedStageId, setSelectedStageId] = useState<string | null>(null);
	const [searchQuery, setSearchQuery] = useState<string>("");

	useEffect(() => {
		dispatch(getUserAsync());
	}, [dispatch]);
	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	const handleOpenCreateModal = () => setCreateModalOpen(true);
	const handleCloseCreateModal = () => setCreateModalOpen(false);

	const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
	const handleOpenEditModal = (stageId: string) => {
		setSelectedStageId(stageId);
		setEditModalOpen(true);
	};
	const handleCloseEditModal = () => {
		setEditModalOpen(false);
		setSelectedStageId(null);
	};

	const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>(
		{},
	);
	const toggleDropdown = (index: number) => {
		setDropdownOpen((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const [refreshData, setRefreshData] = useState(false);
	const [pageNumber, setPage] = useState(1);

	const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
		setPage(newPage);

		if (searchQuery.trim() === "") {
			dispatch(
				getAllStagesAsync({
					companyId,
					page: newPage,
				}),
			);
		} else {
			dispatch(
				searchStageAsync({
					company_id: companyId,
					searchTerm: searchQuery,
					page: newPage,
				}),
			);
		}
	};

	useEffect(() => {
		if (companyId && searchQuery === "") {
			dispatch(getAllStagesAsync({ companyId, page: pageNumber }));
		}
	}, [companyId, pageNumber, dispatch, searchQuery]);
	useEffect(() => {
		if (refreshData) {
			dispatch(getAllStagesAsync({ companyId, page: pageNumber }));
			setRefreshData(false);
		}
	}, [refreshData, companyId, pageNumber, dispatch]);

	const handleCreateSuccess = () => {
		setRefreshData(true);
	};
	const handleEditSuccess = () => {
		setRefreshData(true);
	};
	return (
		<div className="bg-color-8">
			<SettingsLayout>
				<div className="pt-5 p-5">
					<div className="w-100 mb-5 d-inline-flex justify-content-between align-items-center">
						<h4 className="text-color-2">Stages</h4>
						<CloseButton />
					</div>

					<div className="bg-color-9 px-5 pt-3 mt-4">
						<Row>
							<Col md={6} sm={6} xs={12} className="mt-2">
								<Formik
									initialValues={{ searchQuery: "" }}
									validationSchema={Yup.object({
										searchQuery: Yup.string(),
									})}
									onSubmit={async (values, { setSubmitting, setErrors }) => {
										try {
											setSubmitting(true);
											setSearchQuery(values.searchQuery);
											setPage(1);
											if (values.searchQuery.trim() === "") {
												await dispatch(
													getAllStagesAsync({
														companyId,
														page: 1,
													}),
												);
											} else {
												await dispatch(
													searchStageAsync({
														company_id: companyId,
														searchTerm: values.searchQuery,
														page: 1,
													}),
												);
											}
										} catch (error) {
											setErrors({
												searchQuery: "An error occurred. Please try again.",
											});
										} finally {
											setSubmitting(false);
										}
									}}
								>
									{({ isSubmitting, handleSubmit, errors, touched }) => (
										<Form onSubmit={handleSubmit} className="mt-4">
											<div className="mx-2 border border-1 rounded py-1 px-2 d-inline-flex justify-content-between align-items-center my-3">
												<Field
													type="search"
													name="searchQuery"
													placeholder="Search for a stage"
													className="bg-transparent border-0"
													style={{ outline: "0" }}
												/>
												<button
													type="submit"
													className={`pointer ${
														isSubmitting ? "disabled" : ""
													}`}
													disabled={isSubmitting}
													style={{
														background: "none",
														border: "none",
														boxShadow: "none",
													}}
												>
													<img src={searchIcon} alt="search icon" />
												</button>
											</div>
											{errors.searchQuery && touched.searchQuery ? (
												<div className="text-danger">{errors.searchQuery}</div>
											) : (
												<div className="text-transparent">
													errors.searchQuery
												</div>
											)}
										</Form>
									)}
								</Formik>
							</Col>
							<Col md={6} sm={6} xs={12} className="mt-2">
								<Button
									onClick={handleOpenCreateModal}
									className="py-3 px-5 border-0 bg-color-1 float-end"
								>
									<span className="text-center">Add stage</span>
								</Button>
							</Col>
						</Row>
						<Table borderless responsive>
							<thead>
								<tr>
									<th className="text-color-1 bg-color-3">S/N</th>
									<th className="text-color-1 bg-color-3">Stage</th>
									<th className="text-color-1 bg-color-3">Action</th>
								</tr>
							</thead>
							<tbody>
								{Array.isArray(stages) && stages.length > 0 ? (
									stages.map((stage, index) => (
										<tr key={stage.id}>
											<td>{index + 1}</td>
											<td>{stage.stage}</td>
											<td style={{ padding: "0", verticalAlign: "middle" }}>
												<Dropdown
													show={dropdownOpen[index]}
													onToggle={() => toggleDropdown(index)}
												>
													<Dropdown.Toggle
														className="dropdown-toggle"
														variant="transparent"
														style={{
															background: "none",
															border: "none",
															boxShadow: "none",
														}}
													>
														<img
															src={Action}
															alt="Action"
															className="action-icon mb-3"
															style={{ margin: "0", padding: "0" }}
														/>
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() => handleOpenEditModal(stage.id)}
														>
															Edit
														</Dropdown.Item>
														<Dropdown.Item>Delete</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</td>{" "}
										</tr>
									))
								) : (
									<tr>
										<td colSpan={6} className="text-center">
											No stages found.
										</td>
									</tr>
								)}
							</tbody>
						</Table>
						{meta && meta.total_pages > 1 && (
							<div className="d-flex justify-content-center mt-3 pb-5">
								<Pagination
									count={meta && meta?.total_pages}
									page={pageNumber}
									onChange={handleChange}
									shape="rounded"
									color="primary"
								/>
							</div>
						)}
					</div>
				</div>
				<CreateStage
					show={createModalOpen}
					closeModal={handleCloseCreateModal}
					onCreateSuccess={handleCreateSuccess}
				/>
				{selectedStageId && (
					<>
						<EditStage
							show={editModalOpen}
							closeModal={handleCloseEditModal}
							stageId={selectedStageId!}
							onEditSuccess={handleEditSuccess}
						/>
					</>
				)}
			</SettingsLayout>
		</div>
	);
}

export default Stages;
